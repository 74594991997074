import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import BodyContentCoponent from './bodyContentComponent'
import { SocialBox } from './socialComponents'

const Icons = {
	1: require('../img/icon-colour/message-1.svg'),
	2: require('../img/icon-colour/message-2.svg'),
	3: require('../img/icon-colour/message-3.svg'),
	4: require('../img/icon-colour/message-4.svg'),
	5: require('../img/icon-colour/message-5.svg'),
	6: require('../img/icon-colour/message-6.svg'),
	7: require('../img/icon-colour/message-7.svg'),
	8: require('../img/icon-colour/message-8.svg'),
	9: require('../img/icon-colour/message-9.svg'),
	10: require('../img/icon-colour/message-10.svg'),
	11: require('../img/icon-colour/message-11.svg'),
	12: require('../img/icon-colour/message-12.svg'),
}

class KeyContentBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = { 
			showDetail: false
		}
	}

	toggleMessage = (id) => {
		if (id) {
			const element = document.getElementById(id);
			element.scrollIntoView();
		}

		this.setState({
			showDetail: !this.state.showDetail
		})
	}

	componentDidMount() {
		this.checkRouteForDetail()
	}

	checkRouteForDetail = () => {
		const { hash, number } = this.props
		if(hash == `#message${number}`) {
			this.setState({
				showDetail: true
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.hash !== this.props.hash) {
			this.checkRouteForDetail()
		}
	}

	render() {
		const { content, number } = this.props
		const { showDetail } = this.state
		const wrapperClass = showDetail ? 'individual-key-container -active': 'individual-key-container'

		return (
			<div className={wrapperClass} id={`message${number}`}>
				<div className="grid-container -centred">
					<div className="individual-key-message">
						<a onClick={() => this.toggleMessage(null)} className="key-header-container" >
							<div className="key-title-container">
				
								<div className="number-container">
									<img className="key-number -white" src={Icons[number]} alt="" />
								</div>
								<h3 className="key-title">{content.title}</h3>
							</div>
							<div className="message-cta-container">
								<p className="read-more">Read more</p>
								<p className="read-less">Read Less</p>
							</div>
						</a>
						{
							showDetail &&
							content &&
							<div>
								<div className="message-content-container">
									<div className="message-content">
										<BodyContentCoponent
											content={content.data}
											
										/>
									</div>
								</div>
								<SocialBox shareId={`message${number}`} path={this.props.path}/>
								<div className="message-cta-container" onClick={() => this.toggleMessage(`message${number}`)}>
									<p className="read-less">Read Less</p>
								</div>
							</div>
						}
					</div>
				</div>
			</div>		
		)
	}
}

export default KeyContentBlock

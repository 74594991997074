import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import Select, { components } from 'react-select'
import Images from '../img/Images'

const DropdownIndicator = (
  props
) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Images.down}
      	 style={{
    		  width: '16px',
	    	  height: '9px',
	       }}
         alt="drop down"
       />
    </components.DropdownIndicator>
  );
};

const IndicatorsContainer = (props) => {
  return (
    <div style={{
    	display: 'flex',
    	justContent: 'center',
    	height: '65px'
    }}>
      <components.IndicatorsContainer {...props}/>
    </div>
  );
};

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
       	height: '65px',
    	fontSize: '14px',
    	color: '#1F1F1F',
    	fontFamily: 'Klim Regular',
      	backgroundColor: isSelected ? '#EDEDED' : 'white',
       	borderBottom: '1px solid black',
      	color: 'black',
       	padding: 20,
      	cursor: isDisabled ? 'not-allowed' : 'default',
	    ':active': {
	        ...styles[':active'],
	        backgroundColor: '#EDEDED',
      },
    }
  },
  menu: styles => ({ ...styles, marginTop: '0px'  }),
  placeholder: (styles) => ({ ...styles, fontSize: '16px', color: '#1F1F1F', fontFamily: 'Klim Regular' }),
  container: (styles, state) => ({
    ...styles,
    background: '#EDEDED',
  }),
  control: (styles, state) => ({
      ...styles,
			fontFamily: 'Klim Regular',
      background: "#EDEDED",
      borderColor: "black",
      borderRadius: '0px'
    })
}

const IndicatorSeparator = ({ innerProps }) => {
  return (
    <span style={{
		  width: 0,
		}} {...innerProps}/>
  );
};

class CustomSelector extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { options, value, onChange, placeholder } = this.props;
        return (
			     <Select
              value={value}
              onChange={onChange}
              options={options}
            	components={{ IndicatorsContainer, IndicatorSeparator, DropdownIndicator }}
              placeholder={placeholder}
              styles={customStyles}
           />
        )
    }
}

export default CustomSelector

import React, { Component } from 'react';
import { iframeResizer } from 'iframe-resizer';

class Iframe extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        iFrameResize({
            heightCalculationMethod: 'bodyOffset'
        });
    }

    render() {
        return (
            <div className='iframe-wrapper'>
                <h4>{this.props.data.sectionTitle}</h4>
                <iframe
					src={this.props.data.link}
					title={this.props.data.sectionTitle}
                ></iframe>
            </div>
        );
    }
};

export default Iframe;

import { Line } from "react-chartjs-2";
import React from 'react';
import Data from "../utils/data";
import { colours } from "../common/color-pool.js";
import { Decimals } from "../common/decimals.js";
import { find  } from "../common/find";


const ChartG = ({data, chartIndex, vw }) => {
	data = [...data];
	data = Data.cleanNumbers(data);
	let title = find('Title', data);
	let xAxis = find('X axis', data);
	let yAxis = find('Y axis', data);

	let dataObj = data.slice(7);
	let x_axis_labels = dataObj.splice(0, 1);
	x_axis_labels = [...x_axis_labels];
	let labels = x_axis_labels[0].slice(1, x_axis_labels[0].length);

	let datasets = [];
	dataObj.forEach((element, i) => {
		let origElement = [...element];
		let dataset = {
			label: element[0],
			data: element.slice(1).map(x => +x ),
			backgroundColor: colours.schemeTwo[i],
			borderColor: colours.schemeTwo[i],
			fill: false,
			borderWidth: 2,
			lineTension: 0,
			pointRadius: 0
		};
		datasets.push(dataset);
		return origElement;
	});

	const options = {
		aspectRatio: vw <= 750 ? 1 : 1.5,
		responsive: true,
		legend: {
			display: true,
			position: 'bottom',
		},
		title: {
			display: true,
			text: title,
		},
		tooltips: {
			callbacks: {
				label: function (tooltipItem, data) {
					var label = '$' + Decimals(tooltipItem.yLabel);
					return label;
				},
			},
		},
		scales: {
			xAxes: [
				{
					offset: true,
					display: true,
					scaleLabel: {
						display: true,
						labelString: xAxis,
					},
					gridLines: {
						display: false
					}
				},
			],
			yAxes: [
				{
					display: true,
					ticks: {
						beginAtZero: true,
						callback: function (value) {
							return value;
						},
						stepSize: 10
					},
					scaleLabel: {
						display: true,
						labelString: yAxis,
					},
				},
			],
		},
	};


	let chart = {
		labels,
		datasets,
	};

	return (
		<div>
			<div className="chart-g">
				<div className="first">
					<Line data={chart} options={options} height={null} width={null} />
				</div>
			</div>
		</div>
	);
};

export default ChartG;

import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Images from "../img/Images";

const BannerTop = ({ siteTitle }) => (
	<StaticQuery
		query={graphql`
			query bannerTopQuery {
				keyMessages: datoCmsHome {
					dataPortalCopy
					bannerTwoSubtitle
					bannerTwoLink
					bannerOneText
					bannerOneSubtitle
					bannerOneLink
					bannerThreeText
					bannerThreeSubtitle
					bannerThreeLink
					nationalJurisdiction {
						slug
						bodyContent {
							... on DatoCmsHeading {
								id
								keyMessage
							}
						}
					}
				}
				featureArticles: allDatoCmsFeatureArticle(sort: { fields: [position], order: ASC }) {
					edges {
						node {
							title
							slug
							subpageOnly
						}
					}
				}
			}
		`}
		render={(data) => {
			let { keyMessages, featureArticles } = data;

			let keyMessagesArr = [];
			let slug = "";
			if (
				keyMessages &&
				keyMessages.nationalJurisdiction &&
				keyMessages.nationalJurisdiction.slug &&
				keyMessages.nationalJurisdiction.bodyContent
			) {
				keyMessagesArr = keyMessages.nationalJurisdiction.bodyContent.filter(
					(obj) => {
						return obj.keyMessage;
					}
				);
			}
			return (
				<div className="footer-container">
					<div className="footer-container-wrapper">
						<div className='description-block -blue'>
							<div className="centre-container">
								<div className="inner grid-ten -centre">
									<div
										className='data-portal'
										dangerouslySetInnerHTML={{
										__html: keyMessages.bannerOneText,
										}}
									/>
									{keyMessages.bannerOneSubtitle &&
										<div className="banner-subtitle">
											{keyMessages.bannerOneSubtitle}
										</div>
									}
									{keyMessages.bannerOneLink &&
										<a className="data-portal-cta" href={keyMessages.bannerOneLink}>Learn more <img src={Images.topRightWhite}  alt='check out' /></a>
									}
									</div>
								</div>
							</div>
					</div>
				</div>
			);
		}}
	/>
);

BannerTop.propTypes = {
	siteTitle: PropTypes.string,
};

BannerTop.defaultProps = {
	siteTitle: ``,
};

export default BannerTop;

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Images from '../img/Images'


const DownloadBlock = ({content}) => {
	const {title, description, linkAddress, file} = content
	const fixSize = file && ((file.size/1000000).toFixed(2))
	return (
		<div className="down-load-item">
			<div className='block-holder'>
				<div className='block-top'>
					<h4>{title}</h4>
					
				</div>

				<div className='block-bottom'>
					{
						file && <a
							href={file.url.replace('https://www.datocms-assets.com/', 'https://new-energy-guide.aemc.gov.au/')} target='blank'
						>
							Download {fixSize && <span>{`[${fixSize}mb ${file.format.toUpperCase()}]`}</span>}
						</a>
					}
					{
						linkAddress && !file && <a
						href={linkAddress} target='blank'
					>
						Visit link
					</a>
					}
				</div>
			</div>
		</div>
	)
}

const DownloadBlocks = (props) => (
	<StaticQuery
		query={graphql`
			query ReportQuery{
				reports: allDatoCmsReport {
					edges {
						node {
							id
							title
							linkAddress
							showHomepage
							file {
								size
								path
								format
								url
							}

						}
					}
				}
				homeContent: datoCmsHome {
					reportsTitle
				}
			}
		`}
		render={data => {
			const home = data.homeContent;
			const content = data.reports.edges.map(function(obj, i) {
				if ('file' in obj.node && obj.node.showHomepage) {
					return <DownloadBlock content={obj.node} key={i}/>
				}
			})
			return (
				<div className='down-load-container'>
					<div className="centre-container">
						<div className="inner grid-ten -centre">
							<div className='intro-block'>
								<h2 className="intro-heading">{home.reportsTitle}</h2>
							</div>
							<div className='block-table'>
								{content}
							</div>
							<div className='link-block'>
								<p className="reports-link">
								<Link to="/reports-and-documents" className="reports-link -link">
									Click to find out more
								</Link>
								&nbsp;about these smart solar reforms
								</p>
							</div>
						</div>
					</div>
				</div>
			)
		}}
	/>
)

export default DownloadBlocks

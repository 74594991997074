import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import Images from "../img/Images";
import MessagesBlock from "../components/messagesBlock";

const KeyMessages = (props) => (
	<StaticQuery
		query={graphql`
			query KeyMessageListQuery {
				keyMessages: datoCmsHome {
					keyMessagesTitle
					keyMessagesDescription
					nationalJurisdiction {
						title
						bodyContent {
							... on DatoCmsHeading {
								id
								keyMessage
							}
							... on DatoCmsText {
								id
								sectionTitle
								text
							}
							... on DatoCmsImage {
								sectionTitle
								id
								image {
									url
									format
									size
									fluid(
										maxWidth: 900
										imgixParams: { fm: "jpg", auto: "compress" }
									) {
										...GatsbyDatoCmsSizes
									}
								}
							}
							... on DatoCmsHtmlChart {
								sectionTitle
								id
								htmlChart {
									csvFile {
										id
										url
									}
									htmlFile {
										id
										url
										path
									}
								}
							}
							... on DatoCmsTable {
								sectionTitle
								id
								table {
									file {
										csv
										url
										format
										size
									}
								}
							}
							... on DatoCmsChartCollection {
								chartCollection {
									groupTitle
									chartCollection {
										... on DatoCmsChartList {
											chart {
												title
												file {
													csv
													url
													format
													size
												}
												additionalFile {
													csv
													url
													format
													size
												}
												chartType {
													title
												}
												associatedTable {
													title
													file {
														csv
														url
														format
														size
													}
												}
											}
										}
										... on DatoCmsTableList {
											table {
												title
												file {
													csv
													url
													format
													size
												}
											}
										}
										... on DatoCmsChartPairing {
											chartPair {
												title
												chartPairing {
													... on DatoCmsChartList {
														chart {
															title
															file {
																csv
																url
																format
																size
															}
															additionalFile {
																csv
																url
																format
																size
															}
															chartType {
																title
															}
															associatedTable {
																title
																file {
																	csv
																	url
																	format
																	size
																}
															}
														}
													}
													... on DatoCmsTableList {
														table {
															title
															file {
																csv
																url
																format
																size
															}
														}
													}
												}
											}
										}
									}
								}
							}
							... on DatoCmsDatum {
								sectionTitle
								id
								chart {
									chartType {
										title
									}
									file {
										csv
										url
										format
										size
									}
									additionalFile {
										csv
										url
										format
										size
									}
									associatedTable {
										title
										file {
											csv
											url
											format
											size
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => {
			let { keyMessages } = data;
			const nationalJurisdiction = keyMessages.nationalJurisdiction;
			const { location } = props;
			return (
				<MessagesBlock
					content={nationalJurisdiction}
					keyMessagesTitle={keyMessages.keyMessagesTitle}
					keyMessagesDescription={keyMessages.keyMessagesDescription}
					location="Home"
					slug="/"
					hash={location.hash}
					path={location.pathname}
				/>
			);
		}}
	/>
);

KeyMessages.propTypes = {
	// siteTitle: PropType s.string,
};

KeyMessages.defaultProps = {
	// siteTitle: ``,
};

export default KeyMessages;

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'
import Images from '../img/Images'


class HomeHero extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { data } = this.props;
		return (
			<div className="hero">
				<div className="centre-container">
					<div className="inner grid-ten -centre">
						{/* <h2 className="home-title">Post 2025</h2> */}
						<h1 className="home-subtitle">{ data.title }</h1>
						<div className="home-subline" dangerouslySetInnerHTML={{ __html: data.description }}></div>
						<img src={Images.heroImg} />
					</div>
				</div>
			</div>
		)
	}
}

export default HomeHero;

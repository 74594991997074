class Data {
	static cleanNumbers(data) {
		if(!data) return [];
		data.forEach((row, r) => {
			if (row) {
				row.forEach((cell, c) => {
					data[r][c] = cell.replace(/^_/, '');
				});
			}
		});
		return data;
	}
}

export default Data;
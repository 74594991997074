import { HorizontalBar } from "react-chartjs-2";
import React from 'react';
import Data from "../utils/data";
import { colours } from "../common/color-pool.js";
import { Decimals } from "../common/decimals.js";
import { find  } from "../common/find";
import { groupBy } from "lodash";
import { render } from "react-testing-library";


class ChartClcverticalstackbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			legendItems: [],
			chartInstance: [],
		};
		this.chart = [];
	}

	sortArray(data, allRetailers) {
		let sorted = []
		allRetailers.forEach((r) => {
			if(data.includes(r)){
				sorted.push(r);
			}
		})
		return sorted;
	}


	componentDidMount() {
		if(this.chart[0]) {
			const component = this.chart[0];
			Chart.pluginService.register({
				afterDraw: (chartInstance, easing) => {
					if (chartInstance.options.drawPlugin) {
						const ctx = chartInstance.chart.ctx;
						ctx.fillStyle = '#000';
						ctx.font = '400 60% serif';
						const allDatasets = chartInstance.data.datasets;
						allDatasets.forEach((dataset, index) => {
							const lastDataset = index > 0 ? allDatasets[index - 1] : null;
							for (let i = 0; i < dataset.data.length; i++) {
								if(dataset.data[i] !== 0 ) {
									let model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
									let lastx = 0;
									let xpositon;
									if(index > 0 ) {
										lastx = lastDataset._meta[Object.keys(lastDataset._meta)[0]].data[i]._model.x;
										// console.log(lastx)
										xpositon = (model.x - lastx) / 2 + lastx;
									} else {
										xpositon = model.x / 1.5;
									}
									ctx.fillText(`${Math.round(dataset.data[i])}`, xpositon, model.y + 2);
								}	
							}
						});
					}
				}
			})
			component.chartInstance.generateLegend();
			this.setState({ legendItems: component.chartInstance.legend.legendItems });
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			if (this.chart[0]) {
				const component = this.chart[0];
				component.chartInstance.generateLegend();
				this.setState({ legendItems: component.chartInstance.legend.legendItems });
			}
		}
	}

	render () {
		let data = this.props.data;
		let chartIndex = this.props.chartIndex;
		let chartInstances = [];
		data = Data.cleanNumbers(data);

		const find = (key, data) => {
			let obj = data.find(elem => {
				return elem[0] == key;
			});

			if (obj) {
				return obj[1];
			}
			return false;
		}
		
		let xAxis = find('X axis', data);
		let yAxis = find('Y axis', data);
		let dataObj = data.slice(8);
		let stateGroup = {}
		let alternate = false;

		const allState = [ 'OVERALL','ACT','NSW', 'QLD', 'SEQLD', 'VIC','SA','TAS', 'NA'];
		const alternateState = ['Electricity', 'Gas','Water', 'Internet services', 'Insurance', 'Mobile phone', 'Banking', ];
		const allDates = ['Apr-2020', 'Oct-2019', 'Apr-2019','Oct-2018','Apr-2018','Oct-2017', 'Sep-2017','Apr-2017', 'Oct-2016', 'Sep-2016', 'Apr-2016'];

		dataObj.forEach((d, i) => {
			const state = d[3];
			if(!stateGroup[state]) {
				stateGroup[state] = [];
			}
			const item = {
			    'wave_no': d[0],
			    'metric': d[1],
			    'sentiment': d[2],
			    'juris_state': d[3],
			    'wave_date': d[4],
			    'label_order': d[5]
			}
			stateGroup[state].push(item);
		})

		const buildStackData = (data, labels, state) => {
			
			const sentiment = {}
		
			labels.forEach((year) => {

				['Positive', 'Neutral', 'Negative', 'yes', 'no'].forEach((senti) => {
					if(!sentiment[senti]) {
						sentiment[senti] = [];
					}

					const found = data[year].filter((record) => {
						return record.sentiment === senti;
					})
					const value = found.length > 0 ? found[0].metric * 100 : 0;
					
					sentiment[senti].push(value);
				})
			});
			let datasets;

			let altChart = sentiment.yes.reduce(function(a, b) { return a + b; }, 0) + sentiment.no.reduce(function(a, b) { return a + b; }, 0);
			if (altChart !== 0) {
				datasets = [
					{
						label: 'yes',
						data:  sentiment.yes,
						backgroundColor: colours['Neutral'],
						borderWidth: 1
					},
					{
						label: 'no',
						data:  sentiment.no,
						backgroundColor: colours['Negative'],
						borderWidth: 1
					}
				];
			}

			else {
				datasets = [
					{
						label: 'Positive',
						data:  sentiment.Positive,
						backgroundColor: colours['Positive'],
						borderWidth: 1
					},{
						label: 'Neutral',
						data:  sentiment.Neutral,
						backgroundColor: colours['Neutral'],
						borderWidth: 1
					},
					{
						label: 'Negative',
						data:  sentiment.Negative,
						backgroundColor: colours['Negative'],
						borderWidth: 1
					}];
			}
			return datasets;
		}

		let sortedState = this.sortArray(Object.keys(stateGroup), allState);
		if(sortedState.length == 0) {
			alternate = true;
			sortedState = this.sortArray(Object.keys(stateGroup), alternateState);
		}

		const chartCreator = () => {
			let chartArray = [];
			let showLegend = false

			sortedState.forEach((state, index) => {
				const stateData = stateGroup[state];
				const groupYear = groupBy(stateData, 'wave_date');
				const labels = this.sortArray(Object.keys(groupYear), allDates);
				 // Object.keys(groupYear);
				const datasets = buildStackData(groupYear, labels, state);
			
				let newID;
				
				if(index === 0) {
					newID = 'myChart -' + this.props.chartIndex;
				} else {
					newID = 'myChart' + index + ' -' + this.props.chartIndex;
				}
				
				const options = {
					aspectRatio: this.props.vw <= 750 ? 3.5 : 5,
					responsive: (showLegend ? false : true),
					drawPlugin: (this.props.vw > 600 ? true : false),
					legend: {
						display: false,
						position: 'bottom'
					},
					title: {
						display: true,
						text: state,
						position: 'right'
					},
					tooltips: {
						mode: 'nearest',
						callbacks: {
							label: function(tooltipItem, data) {
								return `${Decimals(tooltipItem.xLabel)}%`;
							}
						}
					},
					legendCallback: function(chart) {
						let text = [];
						text.push('<div class=\'legend\' style="list-style-type: none; width: 100%;">');
						for (let i=0; i<chart.data.datasets.length; i++) {
							text.push('<div class="legend-block" style="display: inline-block">');
							text.push('<span style="background-color:' + chart.data.datasets[i].backgroundColor + ';margin-left: 10px;  margin-right: 10px; display: inline-block; width: 30px; height: 10px;"> </span>');
							text.push('<span class="text-block" style="font-family: \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif; font-size: 1.2rem; color: #666">' + chart.data.datasets[i].label + '</span>');
							text.push('</div>');
						}
						text.push('</div>');
						return text.join('');
					},
					scales: {
						xAxes: [
							{
								stacked: true,
								scaleLabel: {
									display: showLegend,
									labelString: 'Percent of respondents'
								},
								ticks: {
									beginAtZero: true,
									max: 100,
									callback: (value, index, values) => {
										return `${value}%`;
									},
									stepSize: 20,
									display: showLegend
									},
							}
						],
						yAxes: [
							{
								stacked: true,
								scaleLabel: {
									display: false,
									labelString: '`${yAxis}`'
								}
							}
						]
					}
				};

				const chart = {
					labels,
					datasets,
				};

				chartArray.push(
					<div className="horizontal-bar" key={index}>
						<HorizontalBar id={newID} height={null} width={null} className="myChart" ref={ref => this.chart[index] = ref} data-key={index} data={chart} options={options} style="padding-bottom: 20px; height: 100%" />
					</div>
					)
				
			})
			return chartArray

		}
		

		return (
			<div>
				<div className="chart-footer">
					<p className="chart-footer-title"></p>
					<div className="legend">
						{this.state.legendItems && this.state.legendItems.length &&
							(
								<div className='legend' >
									<span style={{fontFamily: 
									"'Helvetica Neue', 'Helvetica', 'Arial', sans-serif", fontSize: '12px', color: '#666', fontWeight: 'bold'}}>Sentiment</span>
									{this.state.legendItems.map((item, index) => {
										const spanStyle = {
											backgroundColor: item.fillStyle,
										};
										return (
										<div key ={index}className="legend-block">
										<span
										style={spanStyle}
										className="legend-icon"></span>
										<span className="text-block">{item.text}</span>
										</div>
										);
									})}
								</div>
									)
								}
					</div>
				</div>
				<div className="chart-clcverticalstackbar">
						<div className={"first right-block chart" + chartIndex}>
							{chartCreator()}
						</div>
					</div>
			</div>
		);
	}
}

export default ChartClcverticalstackbar;
import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Images from "../img/Images";
import DownloadBlocks from "./downloadBlocks";

const Footer = ({ siteTitle }) => (
	<StaticQuery
		query={graphql`
			query JurisdictionFooterQuery {
				keyMessages: datoCmsHome {
					nationalJurisdiction {
						slug
						bodyContent {
							... on DatoCmsHeading {
								id
								keyMessage
							}
						}
					}
				}
				featureArticles: allDatoCmsFeatureArticle(sort: { fields: [position], order: ASC }) {
					edges {
						node {
							title
							slug
							subpageOnly
							menuArticles
							menuAbout
						}
					}
				}
			}
		`}
		render={(data) => {
			let { keyMessages, featureArticles } = data;

			let keyMessagesArr = [];
			let slug = "";
			if (
				keyMessages &&
				keyMessages.nationalJurisdiction &&
				keyMessages.nationalJurisdiction.slug &&
				keyMessages.nationalJurisdiction.bodyContent
			) {
				keyMessagesArr = keyMessages.nationalJurisdiction.bodyContent.filter(
					(obj) => {
						return obj.keyMessage;
					}
				);
			}
			return (
				<div className="footer-container">
					<div className="footer-container-wrapper">
						<DownloadBlocks />

						<div className="footer-main">
							<div className="centre-container">
								<div className="inner grid-ten -centre">
									<div className="f-header">
										<Link to="/" className="nav-logo">
											<img src={Images.aemcLogoDark} alt="logo" />
										</Link>
									</div>
									<div className="footer-items grid-container">
										{/* <div className="footer-item">
											<p className="footer-item-header">In Brief</p>
											<div className="footer-sub-container">
												{keyMessagesArr.map((elem, i) => {
													if (elem.keyMessage) {
														return (
															<p key={i}>
																<Link
																	to={`/#message${i + 1}`}
																	className="footer-item-sub-header"
																>
																	{`${elem.keyMessage}`}
																</Link>
															</p>
														);
													}
												})}
											</div>
										</div> */}
										{featureArticles.edges[0] && (
											<div className="footer-item">
												<p className="footer-item-header">Articles</p>

												<div className="footer-sub-container">
													{featureArticles.edges.map((elem, i) => {
														if (elem.node.menuArticles) {
															return (
																<p key={i}>
																	<Link
																		to={`/${elem.node.slug}`}
																		className="footer-item-sub-header"
																	>
																		{elem.node.title}
																	</Link>
																</p>
															);
														} else {
															return "";
														}
													})}
												</div>
											</div>
										)}

										<div className="footer-item">
											<p className="footer-item-header">About us</p>
											<div className="footer-sub-container">
												{featureArticles.edges.map((elem, i) => {
													if (elem.node.menuAbout) {
														return (
															<p key={i}>
																<Link
																	to={`/${elem.node.slug}`}
																	className="footer-item-sub-header"
																>
																	{elem.node.title}
																</Link>
															</p>
														);
													} else {
														return "";
													}
												})}
																																			{/* <p>
													<a
														className="footer-item-sub-header"
														href="https://www.aemc.gov.au/calendar"
														target="_blank"
													>
														 Calendar
													</a>
												</p> */}
											
											</div>
										</div>

										<div className="grid-quarter footer-item">
											<p className="footer-item-header"> Legal</p>
											<div className="footer-sub-container">
                      <p>
													<a
														className="footer-item-sub-header"
														href="https://www.aemc.gov.au/terms-use/terms-use-0"
														target="_blank"
													>
														Privacy Policy
													</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}}
	/>
);

Footer.propTypes = {
	siteTitle: PropTypes.string,
};

Footer.defaultProps = {
	siteTitle: ``,
};

export default Footer;

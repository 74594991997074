import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Images from "../img/Images";

const Banners = ({ siteTitle }) => (
	<StaticQuery
		query={graphql`
			query bannerQuery {
				keyMessages: datoCmsHome {
					dataPortalCopy
					bannerTwoSubtitle
					bannerTwoLink
					bannerOneText
					bannerOneSubtitle
					bannerOneLink
					bannerThreeText
					bannerThreeSubtitle
					bannerThreeLink
					bannerFourText
					bannerFourSubtitle
					bannerFourLink
					nationalJurisdiction {
						slug
						bodyContent {
							... on DatoCmsHeading {
								id
								keyMessage
							}
						}
					}
				}
				featureArticles: allDatoCmsFeatureArticle(sort: { fields: [position], order: ASC }) {
					edges {
						node {
							title
							slug
							subpageOnly
						}
					}
				}
			}
		`}
		render={(data) => {
			let { keyMessages, featureArticles } = data;

			let keyMessagesArr = [];
			let slug = "";
			if (
				keyMessages &&
				keyMessages.nationalJurisdiction &&
				keyMessages.nationalJurisdiction.slug &&
				keyMessages.nationalJurisdiction.bodyContent
			) {
				keyMessagesArr = keyMessages.nationalJurisdiction.bodyContent.filter(
					(obj) => {
						return obj.keyMessage;
					}
				);
			}
			return (
				<div className="footer-container">
					<div className="footer-container-wrapper">
						{keyMessages.dataPortalCopy &&
						<div className="description-block -offwhite">
							<div className="centre-container">
								<div className="inner grid-ten -centre">
									<div
										className="data-portal"
										dangerouslySetInnerHTML={{
											__html: keyMessages.dataPortalCopy,
										}}
									/>
									{keyMessages.bannerTwoSubtitle &&
										<div className="banner-subtitle">
											{keyMessages.bannerTwoSubtitle}
										</div>
									}
									{keyMessages.bannerTwoLink && (
										<a
											className="data-portal-cta"
											href={keyMessages.bannerTwoLink}
										>
											Learn more{" "}
											<img src={Images.topRightBlue} alt="check out" />
										</a>
									)}
								</div>
							</div>
						</div>
						}
						{keyMessages.bannerThreeText &&
                        <div className='description-block -blue'>
                            <div className="centre-container">
                                <div className="inner grid-ten -centre">
                                    <div
                                        className='data-portal'
                                        dangerouslySetInnerHTML={{
                                        __html: keyMessages.bannerThreeText,
                                        }}
                                    />
                                    {keyMessages.bannerTwoSubtitle &&
                                        <div className="banner-subtitle">
                                            {keyMessages.bannerTwoSubtitle}
                                        </div>
                                    }
                                    {keyMessages.bannerThreeLink &&
                                        <a className="data-portal-cta" href={keyMessages.bannerThreeLink}>Learn more <img src={Images.topRightWhite}  alt='check out' /></a>
                                    }
                                    </div>
                                </div>
                            </div>
						}
						{keyMessages.bannerFourText &&
                        <div className='description-block -white'>
                            <div className="centre-container">
                                <div className="inner grid-ten -centre">
                                    <div
                                        className='data-portal'
                                        dangerouslySetInnerHTML={{
                                        __html: keyMessages.bannerFourText,
                                        }}
                                    />
                                    {keyMessages.bannerTwoSubtitle &&
                                        <div className="banner-subtitle">
                                            {keyMessages.bannerTwoSubtitle}
                                        </div>
                                    }
                                    {keyMessages.bannerFourLink &&
                                        <a className="data-portal-cta" href={keyMessages.bannerFourLink}>Learn more <img src={Images.topRightBlue}  alt='check out' /></a>
                                    }
                                    </div>
                                </div>
                            </div>
						}
					</div>
				</div>
			);
		}}
	/>
);

Banners.propTypes = {
	siteTitle: PropTypes.string,
};

Banners.defaultProps = {
	siteTitle: ``,
};

export default Banners;

export const colours = {
	//Misc
	NSW: '#083D77',
	'NSW storage': '#083D77',
	QLD: '#FFE74C',
	'QLD storage': '#FFE74C',
	SA: '#EA3546',
	'SA storage': '#EA3546',
	TAS: '#16E0BD',
	'TAS storage': '#16E0BD',
	VIC: '#DB5ABA',
	'VIC storage': '#DB5ABA',
	'NSW-ACT': '#083D77',
	'New South Wales': '#083D77',
	'Queensland': '#FFE74C',
	'South Australia': '#EA3546',
	'Tasmania': '#16E0BD',
	'Victoria': '#DB5ABA',
	'SA - SA Power Networks': '#EA3546',
	'TAS - TasNetworks (D)': '#16E0BD',
	Reliability: '#d89134',
	Security: '#cf575d',
	Transmission: '#98bbbe',
	Distribution: '#1391da',
	'Binding': '#692c6f',
	'Non-Binding': '#1391da',
	'Black coal': '#1F1F1F',
	'Brown coal': '#964B00',
	'Mainland 2016/17': '#98bbbe',
	'Mainland 2017/18': '#0075A3',
	'Mainland 2018/19': '#253C78',
	'Tasmania 2016/17': '#FFE74C',
	'Tasmania 2017/18': '#d89134',
	'Tasmania 2018/19': '#EC4E20',
	'Origin': '#66C8EF',
	'EA': '#CF7600',
	'ActewAGL' : '#ece5c1',
	'Red/Lumo': '#BF9DC0',
	'Simply': '#debd82',
	'Alinta': '#7D4082',
	'Other': '#b5e486',
	'Aurora Energy': '#B1BCB5',
	'AGL': '#00496E',
	'Tas Gas': '#424242',
	'Negative': '#C3B1BC',
	'Neutral': '#E1DE94',
	'Positive': '#a0ddf5',
	'Standing offer': '#CF7600',
	'Market offer': '#0075A3',

	//Comp Review 2020
	// USED BY T1
	schemeOne: [
		'#00496E',
		'#CF7600',
		'#7D4082',
		'#B1BCB5',
		'#F0E6C0',
		'#00496E',
		'#8B687D',
		'#14ABE6',
		'#404040',
		'#0D0D0D'
	],

	// USED BY T3
	schemeTwo: [
		'#47BAE8',
		'#4FA64C',
		'#0D4799',
		'#B3D450',
		'#0A1C0D',
		'#A0A2A4',
		'#00A5E1',
		'#57585B',
		'#005B82',
		'#66C6EB',
		'#7E4482',
		'#BECB3C',
		'#E48E2D',
		'#FFE18F',
		'#36103C',
		'#20375D',
		'#793782',
		'#B056BC',
		'#EC1C24',
		'#3B7BB3',
		'#014F80',
		'#E0601F',
		'#554742',
		'#A3DCF3',
		'#A7D3A5',
		'#86A3CC',
		'#D9E9A7',
		'#848D86',
		'#CFD0D1',
		'#83CBEE',
		'#A7A9AC',
		'#5E99BA'
	],
	

	schemeThree: [
		'#A0A2A4',
		'#47BAE8',
		'#4FA64C',
		'#0D4799',
		'#B3D450',
		'#0A1C0D',
		'#0D4799',
	],

	schemeFour: [
		'#00496E',
		'#14ABE6',
		'#7D4082',
		'#CF7600',
		'#8B687D',
		'#735022',
	]
};
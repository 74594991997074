export const find = (key, data, i) => {
	let obj = data.find((elem) => {
		return elem[0] == key;
	});

	if (obj && !i) {
		return obj[1];
	}

	else if (obj && i) {
		return obj[i];
	}
	return false;
};
import React from "react";
import { Bar } from "react-chartjs-2";
import Data from "../utils/data";
import { colours } from "../common/color-pool.js";
import { Decimals } from "../common/decimals.js";
import {find} from "../common/find";


const Chart11 = ({ data, vw }) => {
	data = [...data];
	data = Data.cleanNumbers(data);
	let title = find('Title', data);
	let xAxis = find('X axis', data);
	let metric = find('Metric', data);
	let yAxis = find('Y axis', data);

	let dataObj = data.slice(7);
	let x_axis_labels = dataObj.splice(0, 1);
	x_axis_labels = [...x_axis_labels];
	let labels = x_axis_labels[0].slice(1, x_axis_labels[0].length);

	if (metric == 'Value' || metric == '') {
		metric = false
	}

	const addMetric = (value) => {
		if (metric && metric == '$') {
			return metric + Decimals(value);
		}

		else if (metric == '%') {
			return +Decimals(value * 100)+ metric;
		}

		else if (metric) {
			return Decimals(value) + metric;
		}
		else {
			return Decimals(value);
		}
	}

	let datasets = [];
	dataObj.forEach((element, i) => {
		let dataset = {
			label: element[0],
			data: element.slice(1).map(x => +x ),
			backgroundColor: colours.schemeTwo[i],
			borderColour: colours.schemeTwo[i],
			borderWidth: 2,
			lineTension: 0,
		};
		datasets.push(dataset);
	});

	const options = {
		aspectRatio: vw <= 750 ? 1 : 2,
		responsive: true,
		legend: {
			display: datasets.length > 1 ? true : false,
			position: 'bottom'
		},
		title: {
			display: true,
			text: title
		},
		tooltips: {
			callbacks: {
				label: function (tooltipItem, data) {
					var label = addMetric(tooltipItem.yLabel);
					return label;
				},
			},
		},
		scales: {
			yAxes: [
				{
					display: true,
					scaleLabel: {
						display: true,
						labelString: yAxis
					},

					ticks: {
						beginAtZero: true,
						stepSize: 2000,
						// Include a dollar sign in the ticks
						callback: (value, index, values) => {
							return `${addMetric(value / 1000)}K`;
						}
					}
				}
			],
			xAxes: [
				{
					display: true,
					scaleLabel: {
						display: true,
						labelString: xAxis
					},
					gridLines: {
						display: false
					},
				}
			]
		}
	};


	let chart = {
		labels,
		datasets,
	};

	return (
		<div>
			<div className="chart-11">
				<div className="first">
					<Bar data={chart} options={options} height={null} width={null}/>
				</div>
			</div>
		</div>
	);
};

export default Chart11;

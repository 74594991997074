import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Images from "../img/Images";
import Menu from "./menu";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			search: "",
			showSearch: false,
		};
	}

	toggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	renderHeader = () => {
		const { home, title } = this.props;
		const subheaderTask = home ? "menu-subheader -home" : "menu-subheader";

		return (
			<header className={home ? "header -home" : "header"}>
				<Link to="/" className="nav-logo">
					<img src={Images.logoExtended} alt="DER" />
					{title && <h1 className="hero-title">{title}</h1>}
					{/* <p className={subheaderTask}>Retail energy competition review 2020</p> */}
				</Link>
				<div className="nav-button-container">
					<div className="desktop-nav-buttons">
						<button
							className="open-menu"
							aria-label="Toggle Menu"
							onClick={this.toggleModal}
							style={{
								background: "none",
								border: "none",
								marginLeft: 0,
							}}
						>
							Menu
						</button>
						<button
							className="open-menu"
							aria-label="Toggle Search"
							onClick={this.toggleSearch}
							style={{
								background: "none",
								border: "none",
								marginRight: 0,
							}}
						>
							Search
						</button>
						<Link
							to="/reports-and-documents"
							className="open-menu"
							style={{
								background: "none",
								border: "none",
								marginRight: 0,
								marginLeft: "20px"
							}}
						>
							Documents
						</Link>
						{/* <a
							className="open-menu"
							href="https://www.aemc.gov.au/calendar"
							target="_blank"
							style={{
								background: "none",
								border: "none",
								marginRight: 0,
								marginLeft: "20px"
							}}
						>
							Calendar
						</a> */}
					</div>
					<div className="mobile-nav-buttons">
						<button
							className="open-menu"
							aria-label="Toggle Search"
							onClick={this.toggleSearch}
							style={{
								background: "none",
								border: "none",
							}}
						>
							<img className="search-logo" src={Images.search} alt="search" />
						</button>
						<button
							className="open-menu"
							aria-label="Toggle Menu"
							onClick={this.toggleModal}
							style={{
								background: "none",
								border: "none",
							}}
						>
							<img className="menu-logo" src={Images.menu} alt="menu" />
						</button>
					</div>
				</div>
				{this.state.showModal && (
					<Menu home={home} toggleModal={this.toggleModal} />
				)}
			</header>
		);
	};

	onChangeText = (e) => {
		this.setState({ search: e.target.value });
	};

	handleKeyPress = (e) => {
		if (e.key === "Enter") {
			this.handleSearch(e);
		}
	};

	handleSearch = (e) => {
		e.preventDefault();
		const { search } = this.state;
		if (!search) return;

		navigate(`/search?keyword=${this.state.search}`);
	};

	renderSearch = () => {
		return (
			<header className="header">
				<div className="search-wrapper">
					<div className="input-container">
						<input
							className="search-bar"
							value={this.state.search}
							type="text"
							name="search"
							placeholder="Search..."
							onChange={this.onChangeText}
							onKeyPress={this.handleKeyPress}
						/>
						<img src={Images.search} className="search-icon" alt="DER" />
					</div>
					<button onClick={this.toggleSearch} className="close-icon">
						<a className="">CLOSE</a>
					</button>
				</div>
			</header>
		);
	};

	toggleSearch = () => {
		this.setState({ showSearch: !this.state.showSearch });
	};

	render() {
		const { home } = this.props;
		const { showSearch } = this.state;
		return (
			<div className="header-wrapper">
				<div className="centre-container -remove-padding">
					{home && (
						<div className="inner grid-full -centre">
							<div className="header-inner">
								{showSearch ? this.renderSearch() : this.renderHeader()}
							</div>
						</div>
					)}
					{!home && (
						<div className="inner grid-full -centre">
							{showSearch ? this.renderSearch() : this.renderHeader()}
						</div>
					)}
				</div>
			</div>
		);
	}
}

Header.propTypes = {
	home: PropTypes.bool,
};

Header.defaultProps = {
	home: false,
};

export default Header;

import { Bar } from "react-chartjs-2";
import React from "react";
import Data from "../utils/data";
import { colours } from "../common/color-pool.js";
import { Decimals } from "../common/decimals.js";
import { find } from "../common/find";
import { groupBy } from "lodash";
import lineData from "../json/charting_data_gas_hhi_line.json";
import barData from "../json/charting_data_gas_market_share_part_bar.json";

class ChartMixstackbarlinegas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			legendItems: [],
			chartInstance: [],
		};
		this.chart = [];
		this.legendClickCallback = this.legendClickCallback.bind(this);
	}

	sortArray(data, allRetailers) {
		let sorted = [];
		allRetailers.forEach((r) => {
			if (data.includes(r)) {
				sorted.push(r);
			}
		});

		return sorted;
	}

	legendClickCallback = (item, legendItemIndex) => {
		if (
			item.currentTarget.getElementsByClassName("text-block")[0].style
				.textDecoration === "line-through"
		) {
			item.currentTarget.getElementsByClassName(
				"text-block"
			)[0].style.textDecoration = "none";
		} else {
			item.currentTarget.getElementsByClassName(
				"text-block"
			)[0].style.textDecoration = "line-through";
		}
		this.chart.map((chart, i) => {
			var dataItem = chart.chartInstance.config.data.datasets[legendItemIndex];
			if (dataItem) {
				if (dataItem.hidden == true) {
					dataItem.hidden = false;
				} else {
					dataItem.hidden = true;
				}
			}
			chart.chartInstance.update();
		});
	};

	componentDidMount() {
		const component = this.chart[0];
		component.chartInstance.generateLegend();
		this.setState({ legendItems: component.chartInstance.legend.legendItems });
	}

	render() {
		const allRetailers = [
			"Other",
			"Tas Gas",
			"Aurora Energy",
			"Simply",
			"Alinta",
			"Red/Lumo",
			"ActewAGL",
			"EA",
			"Origin",
			"AGL",
		];

		const allState = ["NSW", "QLD", "SA", "VIC", "ACT", "TAS"];
		const allBar = groupBy(barData, "state");
		const allLine = groupBy(lineData, "state");

		const buildLineData = (data) => {
			let lineData = [],
				lneLabel = [];
			data.forEach((e, i) => {
				let label = e["year"];
				const data = e["hhi"];
				lineData.push(data);
				lneLabel.push(label);
			});

			return {
				type: "line",
				label: "HHI (RHS)",
				data: lineData,
				fill: false,
				backgroundColor: "#000000",
				borderColor: "#000000",
				lineTension: 0,
				pointRadius: 2,
				yAxisID: "right-axis",
				order: 2,
			};
		};

		const buildBarData = (data) => {
			const groupYears = groupBy(data, "year");
			const years = Object.keys(groupYears);

			const groupRetailers = groupBy(data, "retailer");

			let datasets = [];

			allRetailers.map((provider, i) => {
				let set = {
					type: "bar",
					label: provider,
					backgroundColor: colours[provider],
					data: [],
					yAxisID: "left-axis",
					order: 2,
				};

				years.forEach((year) => {
					if (groupRetailers[provider]) {
						const providerDetail = groupRetailers[provider].find((i) => {
							return i.year === year;
						});

						if (providerDetail) {
							set.data.push(providerDetail.market_share * 100);
						} else {
							set.data.push(0);
						}
					} else {
						set.data.push(0);
					}
				});

				datasets.push(set);
			});

			return {
				datasets,
				labels: years,
			};
		};

		const leftLabel = "Market share (%)";
		const rightLabel = "HerfindahI-Hirscgman Index (HHI)";

		const sortedState = this.sortArray(Object.keys(allBar), allState);

		const allStateData = sortedState.map((state, i) => {
			const lineData = allLine[state];
			const barData = allBar[state];
			const title = state;
			const barChartData = buildBarData(barData);
			const lineDatasets = buildLineData(lineData);
			barChartData.datasets.unshift(lineDatasets);
			return { barChartData, title };
		});

		const find = (key, data) => {
			let obj = data.find((elem) => {
				return elem[0] == key;
			});

			if (obj) {
				return obj[1];
			}
			return false;
		};

		let chartArray = [];

		const chartCreator = () => {
			allStateData.map((year, index) => {
				const { barChartData, title } = year;
				let newID;
				if (index === 0) {
					newID = "myChart";
				} else {
					newID = "myChart" + index;
				}

				const showLeftBar = index === 0 ? true : false;
				const showRightBar =
					index === allStateData.length - 1 ? true : false;
				const options = {
					aspectRatio: 0.5,
					responsive: true,
					tooltips: {
						mode: "index",
						intersect: true,
						itemSort: function(a, b) {
							return b.datasetIndex - a.datasetIndex;
					 	},
						callbacks: {
							label: function (tooltipItem, data) {
								var label = Decimals(tooltipItem.yLabel);
								if (label <= 100 ) {
									return label + '%';
								}
								else {
									return label + ' (HHI)'
								}
							},
						},
					},
					legend: {
						display: false,
						position: "bottom",
					},
					legendCallback: function(chart) {
						let text = [];
						text.push(
							"<div class='legend' style=\"list-style-type: none; width: 100%;\">"
						);
						for (let i = 0; i < chart.data.datasets.length; i++) {
							text.push(
								'<div class="legend-block" style="display: inline-block">'
							);
							text.push(
								'<span style="background-color:' +
									chart.data.datasets[i].backgroundColor +
									';margin-left: 10px;  margin-right: 10px; display: inline-block; width: 30px; height: 10px;"> </span>'
							);
							text.push(
								"<span class=\"text-block\" style=\"font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif; font-size: 1.2rem; color: #666\">" +
									chart.data.datasets[i].label +
									"</span>"
							);
							text.push("</div>");
						}
						text.push("</div>");
						return text.join("");
					},
					title: {
						display: true,
						text: title,
					},
					scales: {
						xAxes: [
							{
								display: true,
								stacked: true,
								scaleLabel: { display: false, labelString: "time" },
							},
						],
						yAxes: [
							{
								type: "linear",
								id: "left-axis",
								stacked: true,
								display: showLeftBar,
								position: "left",
								max: 100,
								ticks: {
									beginAtZero: true,
									padding: 5,
									max: 100,
									callback: (value, index, values) => {
										return `${value}%`;
									},
									stepSize: 25,
								},
								gridLines: {
									drawTicks: false
								},
								scaleLabel: { 
									display: false, 
									labelString: leftLabel,
								},
							},
							{
								type: "linear",
								id: "right-axis",
								display: showRightBar,
								position: "right",
								stacked: false,
								ticks: {
									padding: 5,
									beginAtZero: true,
									max: 10000,
									stepSize: 2500,
								},
								gridLines: {
									drawTicks: false
								},
								scaleLabel: { 
									display: false, 
									labelString: rightLabel,
							
								},
							},
						],
					},
				};
				chartArray.push(
					<div className="one-six" key={index}>
						<Bar id={newID} className="myChart" ref={ref => this.chart[index] = ref} data-key={index} data={barChartData} options={options} height={null} width={null}/>
					</div>
				);
			});
			return chartArray;
		};

		return (
			<div>
				<div className="chart-mixstackbarline">
					<div className="left-block">{leftLabel}</div>
					<div className="center-block">
							{chartCreator()}
					</div>
					<div className="right-block">{rightLabel}</div>
				</div>
				<div className="chart-footer">
					<p className="chart-footer-title" />
					<div className="legend">
						{this.state.legendItems && this.state.legendItems.length && (
							<div className="legend">
								{this.state.legendItems.reverse().map((item, index) => {
									let colorIndex = index == 0 ? 1 : 0;
									const spanStyle = {
										backgroundColor: item.fillStyle,
									};
									return (
										<div
											key={index}
											className="legend-block"
											data-value={item.text}
											onClick={(e) => this.legendClickCallback(e, 10 - index)}
										>
											<span style={spanStyle} className="legend-icon" />
											<span className="text-block">{item.text}</span>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default ChartMixstackbarlinegas;

const Images = {
	hamburger: require('./hamburger.svg'),
	close: require('./hamburger-close.svg'),
	logoBlack: require('./logo-aemc-simple.svg'),
	logoSimple: require('./esb-simple.svg'),
	topRightWhite: require('./top_right_white.svg'),
	topRightBlue: require('./top-right-blue.svg'),
	down: require('./icon-down-carrot.svg'),
	mapJurisdiction: require('./graphic-outline-w-dots.svg'),
	search: require('./icon-search.svg'),
	searchBlack: require('./icon-search-black.svg'),
	menu: require('./icon-menu.svg'),
	herobg: require('./grid-bg.svg'),
	heroImg: require('./hero-graphic-der.svg'),
	logoExtended: require('./aemc-logo-white.svg'),
	aemcLogoDark: require('./aemc-logo-dark.svg'),
	aemoLogo: require('./aemo-logo.svg'),
	aemcLogo: require('./aemc-logo.svg'),
	aerLogo: require('./aer-logo.svg'),
};

export default Images;

import React, { Component } from 'react'
import Pym from 'pym.js'

class PymIframe extends Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		let { id, htmlChart } = this.props

		if (
			htmlChart &&
			htmlChart.htmlFile
		) {
			let file = htmlChart.htmlFile.path.split('/');
			file = file[file.length - 1].replace('.html','');
			let link = `${process.env.GATSBY_URL}htmls/${file}`;
			new Pym.Parent(id, link, {});
		}
	}

	render() {
		let { title, htmlChart, id } = this.props
		let description = '';
		if (
			htmlChart &&
			htmlChart.description
		) {
			description = htmlChart.description;
		}
		let size, format, url
		if(htmlChart && htmlChart.csvFile) {
			url = htmlChart.csvFile. url
			format = htmlChart.csvFile.format
			size = (htmlChart.csvFile.size/1024).toFixed(2)
		}
		
		return (
			<div className='pym-iframe-wrapper'>
				<h4
					className="sub_content_title"
					style={{
						textTransform: 'capitalize'
					}
				}>
					{title}
				</h4>
				<div id={id}></div>
				<div>
					{
						url && 
						<a href={htmlChart.csvFile.url} target='blank'>DOWNLOAD CHART DATA {`[${size} kB ${format ? format.toUpperCase(): ''}]`}</a>
					}
				</div>
				<div>
					{description &&
						<div
							className="description"
						>
							<p
								dangerouslySetInnerHTML={{
									__html: description
								}}
							/>
						</div>
					}
				</div>
			</div>
		)
	}
};

export default PymIframe

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChartD from "../components/chart-d"
import ChartD2 from "../components/chart-d2"
import ChartG from "../components/chart-g"
import Chart11 from "../components/chart-11"
import ChartT5 from "../components/chart-t5"
import ChartResiendtelec from "../components/chart-resiendtelec";
import ChartMixstackbarline from "../components/chart-mixstackbarline";
import ChartMixstackbarlinegas from "../components/chart-mixstackbarlinegas";
import ChartClcverticalstackbar from "../components/chart-clcverticalstackbar";
import NestedTab from './tabbedContent';
import Table from '../components/table';

class TabbedContentComponent extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.renderDatum = this.renderDatum.bind(this);
		this.state = {
			value: undefined,
			charts: {
				D: ChartD,
				D2: ChartD2,
				G: ChartG,
				11: Chart11,
				T5: ChartT5,
				Resiendtelec: ChartResiendtelec,
				Mixstackbarline: ChartMixstackbarline,
				Mixstackbarlinegas: ChartMixstackbarlinegas,
				Clcverticalstackbar: ChartClcverticalstackbar
			}
		};
	}

	handleChange = (event) => {
		const value = event.target.value;
		this.setState({
			value: value,
		});
	};

	renderDatum = () => {
		let obj, chartMap, currentValue = '';
		const charts = this.state.charts;
		if (this.props.data.chartCollection) {
			chartMap = this.props.data.chartCollection.chartCollection;
		}
		else if (this.props.data.chartPair) {
			chartMap = this.props.data.chartPair.chartPairing;
		}
		if(this.state.value) {
			currentValue = this.state.value
		}
		else {
			if (chartMap[0].chart && chartMap[0].chart.title) {
				currentValue = chartMap[0].chart.title
			}
			else if (chartMap[0].table && chartMap[0].table.title) {
				currentValue = chartMap[0].table.title;
			}
			else if (chartMap[0].chartPair && chartMap[0].chartPair.title) {
				currentValue = chartMap[0].chartPair.title;
			}
		}
		chartMap.map((chartObject, j) => {
			if (chartObject.chart && chartObject.chart.title == currentValue) {
				obj = chartObject;
			}
			else if(chartObject.chartPair && chartObject.chartPair.title == currentValue) {
				obj = chartObject;
			}
			else if(chartObject.table && chartObject.table.title == currentValue) {
				obj = chartObject;
			}
		})
		if (obj) {
			if (obj.chartPair) {
				return (<NestedTab data={obj} vw={this.props.vw}></NestedTab>);
			}
			else if (obj.table) {
				return (
					<section
					className='content_section'
				>
						<Table
							dataObj={obj.table.file} titleBoolean={true}
						/>
					</section>
				)
			}
			else {
				let chartType = obj.chart.chartType.title
				let Chart = charts[chartType]

				if (Chart) {
					let description = '';

					if ('chartDescriptionNode' in obj.chart) {
						description = obj.chart.chartDescriptionNode.childMarkdownRemark.html;
					}

					let format, size, additionalFormat, additionalSize;

					if (obj.chart.file) {
					format = obj.chart.file.format;
					size = (obj.chart.file.size/1024).toFixed(2);
					}

					if (obj.chart.additionalFile) {
						additionalFormat = obj.chart.file.format;
						additionalSize = (obj.chart.file.size/1024).toFixed(2);
					}

					return <section
						className='content_section'
					>
						<h4 className="chart-title">{obj.sectionTitle}</h4>
						<Chart
							data={obj.chart.file.csv} vw={this.props.vw}
							chartIndex={obj.chart.chartType.title}
						/>
						{
							obj.chart.file.url && (
							<a
								href={obj.chart.file.url}
								target='blank'
								className={'chart-download'}
							>
								DOWNLOAD CHART DATA {`[${size} kB ${format ? format.toUpperCase(): ''}]`}
							</a>
							)
						}
						{
							obj.chart.additionalFile && obj.chart.additionalFile.url && (
							<a
								href={obj.chart.additionalFile.url}
								target='blank'
								className={'chart-download'}
							>
								DOWNLOAD ADDITIONAL CHART DATA {`[${additionalSize} kB ${additionalFormat ? additionalFormat.toUpperCase(): ''}]`}
							</a>
							)
						}
						{
							description &&
							<div
								className="description"
								dangerouslySetInnerHTML={{
									__html: description
								}}
							/>
						}
						{obj.chart.associatedTable && (
							<Table dataObj={obj.chart.associatedTable.file} titleBoolean={false}
								/>
						)}
					</section>
				}
			}
		}
	}

	render() {
		const data = this.props.data;
		let chartMap;
		if (data.chartCollection) {
			chartMap = data.chartCollection.chartCollection;
		}
		else if (data.chartPair) {
			chartMap = data.chartPair.chartPairing;
		}

		return (
			<div className="tabbed-content-container">
				<select
					className="tabbed-content-selector"
					value={this.state.value}
					onChange={this.handleChange}
				>
						{chartMap.map((chartObject, i) => {
							if (chartObject.chart && chartObject.chart.title) {
								return (
									<option 
										value={chartObject.chart.title}
										key={i}
									>
										{chartObject.chart.title}
									</option>
								)
							}
							else if (chartObject.table && chartObject.table.title) {
								return (
									<option 
										value={chartObject.table.title}
										key={i}
									>
										{chartObject.table.title}
									</option>
								)
							}
							else if (chartObject.chartPair && chartObject.chartPair.title) {
								return (
									<option 
										value={chartObject.chartPair.title}
										key={i}
									>
										{chartObject.chartPair.title}
									</option>
								)
							}
						})}
				</select>
				{this.renderDatum()}
			</div>
		);
	}
}

TabbedContentComponent.propTypes = {
	item: PropTypes.object,
};

const TabbedContent = ({ data, vw }) => {
	if (!data) return null;
	return (
		<div className="tabbed-content-container">
			<TabbedContentComponent data={data} vw={vw} />
		</div>
	);
};

TabbedContent.propTypes = {
	data: PropTypes.object,
};

export default TabbedContent;

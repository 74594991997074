import React from "react";
import { Bar } from "react-chartjs-2";
import Data from "../utils/data";
import { colours } from "../common/color-pool.js";
import { Decimals } from "../common/decimals.js";
import {find} from "../common/find";


const ChartD2 = ({ data, vw }) => {
	data = [...data];
	data = Data.cleanNumbers(data);
	let title = find('Title', data);
	let altChart = false;
	if (title == 'Gross margin by retailer tier') {
		altChart = true
	}
	let xAxis = find('X axis', data);
	let yAxis = find('Y axis', data);
	let yAxis2 = (find('Y axis', data, 2));

	let dataObj = data.slice(7);
	let x_axis_labels = dataObj.splice(0, 1);
	x_axis_labels = [...x_axis_labels];
	let labels = x_axis_labels[0].slice(1, x_axis_labels[0].length);

	let datasets = [];
	dataObj.forEach((element, i) => {
		let dataset = {
			label: element[0],
			data: element.slice(1).map(x => +x / 1000),
			backgroundColor: colours.schemeTwo[i]
			,
			borderWidth: 1,
		};
		datasets.push(dataset);
	});

	const options = {
		aspectRatio: vw <= 1000 ? 1 : 1.5,
		responsive: true,
		legend: {
			display: false,
			reverse: altChart,
			position: 'top',
		},
		title: {
			display: yAxis2 ? true : false,
			text: yAxis2 ? yAxis2 : '',
			position: 'right'
			
		},
		tooltips: {
			callbacks: {
				label: function (tooltipItem, data) {
					var label = data.datasets[tooltipItem.datasetIndex].label + ": " + Decimals(tooltipItem.yLabel).toLocaleString();
					return label;
				},
			},
		},
		scales: {
			xAxes: [
				{
					stacked: true,
					position: 'top',
					scaleLabel: {
						display: true,
						labelString: xAxis
					},
					ticks: {
						autoSkip: true,
					},
					gridLines: {
						display: false,
					}
				},
			],
			yAxes: [
				{
					stacked: true,
					scaleLabel: {
						display: true,
						labelString: yAxis,
					},
					ticks: {
						callback: function (value) {
							return (value).toLocaleString();
						},
						stepSize: 5,
					},
				},
			],
		},
	};


	let chart = {
		labels,
		datasets,
	};

	return (
		<div>
			<div className="chart-d">
				<div className="first">
					<Bar data={chart} options={options} height={null} width={null} />
				</div>
			</div>
		</div>
	);
};

export default ChartD2;

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from "gatsby"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Header from './header'
import Footer from './footer'
import DownloadBlocks from './downloadBlocks'

import '../styles/index.scss'

const TemplateWrapper = ({ children, seo }) => (
	<StaticQuery query={graphql`
		query LayoutQuery
		{
			datoCmsSite {
				globalSeo {
					siteName
				}
				faviconMetaTags {
					...GatsbyDatoCmsFaviconMetaTags
				}
			}
			datoCmsHome {
				seoMetaTags {
					...GatsbyDatoCmsSeoMetaTags
				}
			}
		}
	`}
	render={data => (
		<div className="container">
			<HelmetDatoCms
				favicon={data.datoCmsSite.faviconMetaTags}
				seo={seo || data.datoCmsHome.seoMetaTags}
			/>
			<div className='app-container'>
				{children}
				 <Footer />
			</div>
		</div>
		)}
	/>
)


export default TemplateWrapper

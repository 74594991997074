import React from "react";
import { StaticQuery, graphql, Link, navigate } from "gatsby";
import Images from "../img/Images";

export default (props) => (
  <StaticQuery
    query={graphql`
      query JurisdictionHeadingQuery {
        featureArticles: allDatoCmsFeatureArticle(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              title
              slug
              subpageOnly
              menuArticles
              menuAbout
            }
          }
        }
        keyMessages: datoCmsHome {
          nationalJurisdiction {
            slug
            bodyContent {
              ... on DatoCmsHeading {
                id
                keyMessage
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      let { keyMessages, featureArticles } = data;

      let keyMessagesArr = [];
      let slug = "";
      if (
        keyMessages &&
        keyMessages.nationalJurisdiction &&
        keyMessages.nationalJurisdiction.slug &&
        keyMessages.nationalJurisdiction.bodyContent
      ) {
        keyMessagesArr = keyMessages.nationalJurisdiction.bodyContent.filter(
          (obj) => {
            return obj.keyMessage;
          }
        );
        // slug = keyMessages.nationalJurisdiction.slug;
      }
      const { home } = props;
      const headClass = home ? "home menu-modal" : "menu-modal";
      return (
        <div className={headClass}>
          <div className="centre-container -remove-padding-top">
            <div className="inner grid-full -centre">
              <div className="header">
                <a className="nav-logo" href="/">
                  <img src={Images.logoExtended} alt="DER" />
                </a>

                <div className="menu-button">
                  <button
                    onClick={props.toggleModal}
                    style={{
                      background: "none",
                      border: "none",
                    }}
                  >
                    <p className="menu-button-text">Close </p>
                  </button>
                </div>
              </div>

              <div className="menu-item-container">
                {/* <div className="column-third">
                  <h3>Key Messages</h3>
                  <ul>
                    {keyMessagesArr.map((elem, i) => {
                      if (elem.keyMessage) {
                        return (
                          <li key={i}>
                            <Link
                              to={`/#message${i + 1}`}
                              onClick={(e) => {
                                props.toggleModal();
                              }}
                            >
                              {`+ ${elem.keyMessage}`}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div> */}
                <div className="column-third">
                  <h3>Articles</h3>
                  <ul>
                    {featureArticles.edges.map((elem, i) => {
                      if (elem.node.menuArticles) {
                        return (
                          <li key={i}>
                            <Link to={`/${elem.node.slug}`}>
                              + {elem.node.title}
                            </Link>
                          </li>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </ul>
                </div>
                <div className="column-third">
                  <h3>About</h3>
                  <ul>
                    {featureArticles.edges.map((elem, i) => {
                      if (elem.node.menuAbout) {
                        return (
                          <li key={i}>
                            <Link to={`/${elem.node.slug}`}>
                              + {elem.node.title}
                            </Link>
                          </li>
                        );
                      }
                    })}
                    {/* <li>
                      <a href="https://www.aemc.gov.au/calendar" target="_blank">
                        + Calendar
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="column-third">
                  <h3>Legal</h3>
                  <ul>
                    <li>
                      <a
                        href="https://www.aemc.gov.au/terms-use/terms-use-0"
                        target="_blank"
                      >
                        + Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }}
  />
);

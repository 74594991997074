import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import Images from "../img/Images";

const Infographics = (props) => (
  <StaticQuery
    query={graphql`
		query HomeInfographicsQuery {
			home: datoCmsHome {
				infographicTitle
				infographicDescription
				infographicLinkText
				infographicLinkUrl
				infographicImageOne  {
					url
					format
					size
					fluid(
						maxWidth: 900
						imgixParams: { fm: "jpg", auto: "compress" }
					) {
						...GatsbyDatoCmsSizes
					}
				}
				infographicImageOneLink
				infographicImageTwo  {
					url
					format
					size
					fluid(
						maxWidth: 900
						imgixParams: { fm: "jpg", auto: "compress" }
					) {
						...GatsbyDatoCmsSizes
					}
				}
				infographicImageTwoLink
				infographicImageThree  {
					url
					format
					size
					fluid(
						maxWidth: 900
						imgixParams: { fm: "jpg", auto: "compress" }
					) {
						...GatsbyDatoCmsSizes
					}
				}
				infographicImageThreeLink
				infographicImageFour  {
					url
					format
					size
					fluid(
						maxWidth: 900
						imgixParams: { fm: "jpg", auto: "compress" }
					) {
						...GatsbyDatoCmsSizes
					}
				}
				infographicImageFourLink
				infographicImageFive  {
					url
					format
					size
					fluid(
						maxWidth: 900
						imgixParams: { fm: "jpg", auto: "compress" }
					) {
						...GatsbyDatoCmsSizes
					}
				}
				infographicImageFiveLink
				infographicImageSix  {
					url
					format
					size
					fluid(
						maxWidth: 900
						imgixParams: { fm: "jpg", auto: "compress" }
					) {
						...GatsbyDatoCmsSizes
					}
				}
				infographicImageSixLink
			}
		}
    `}
    render={(data) => {
      const home = data.home;
      return (
		<div className="infographic-container">
		<div className="centre-container">
			<div className="inner grid-ten -centre">
				<div className="intro-block">
					<h2 className="intro-heading">
						{home.infographicTitle}
					</h2>
					<div className="intro-copy">
						<div
							dangerouslySetInnerHTML={{
								__html: home.infographicDescription,
							}}
						/>
					</div>
					{home.infographicLinkText && (
						<a
							className="data-portal-cta"
							href={home.infographicLinkUrl}
							target="_blank"
							download
							target="_blank"
						>
							{home.infographicLinkText}
							<img src={Images.topRightBlue} alt="check out" />
						</a>
					)}
				</div>
			</div>
			<div className="inner grid-ten -centre">
				<div className="infographic-card-wrapper">
				{home.infographicImageOne && (
					<a className="single-card" href={home.infographicImageOneLink} target="_blank" download>
						<img src={home.infographicImageOne.url} alt="download" />
					</a>
				)}
				{home.infographicImageTwo && (
					<a className="single-card" href={home.infographicImageTwoLink} target="_blank" download>
						<img src={home.infographicImageTwo.url} alt="download" />
					</a>
				)}
				{home.infographicImageThree && (
					<a className="single-card" href={home.infographicImageThreeLink} target="_blank" download>
						<img src={home.infographicImageThree.url} alt="download" />
					</a>
				)}
				{home.infographicImageFour && (
					<a className="single-card" href={home.infographicImageFourLink} target="_blank" download>
						<img src={home.infographicImageFour.url} alt="download" />
					</a>
				)}
				{home.infographicImageFive && (
					<a className="single-card" href={home.infographicImageFiveLink} target="_blank" download>
						<img src={home.infographicImageFive.url} alt="download" />
					</a>
				)}
				{home.infographicImageSix && (
					<a className="single-card" href={home.infographicImageSixLink} target="_blank" download>
						<img src={home.infographicImageSix.url} alt="download" />
					</a>
				)}
				</div>
			</div>
		</div>
	</div>
      );
    }}
  />
);

export default Infographics;

export const Decimals = ( data ) => {
	let cleanedArray = [];
	let y;
	let isArrayVar = false;

	const foreignSymbolClean = (data) => {
		if (isNaN(+data[0])) {
			data = data.slice(1);
		}
		else if (isNaN(+data[data.length])) {
			data = data.slice(0, data.length - 1);
		}
		return data;

	};

	const decimalsFunction = (data) => {
		if (isNaN(+data)) {
			data = foreignSymbolClean(data);
		}
		let match = (''+data).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
		if (!match) { return 0; }
		let x = Math.max(
			0,
			/// Number of digits right of decimal point.
			(match[1] ? match[1].length : 0)
			// Adjust for scientific notation.
			- (match[2] ? +match[2] : 0)
		);


		if (x < 2) {
			if (isArrayVar) {
				return parseFloat(data);
			}

			else {
				y = parseFloat(data);
			}
		}
		else {
			if (isArrayVar) {

				return parseFloat(data).toFixed(2);
			}
			else {
				y = parseFloat(data).toFixed(2);
			}
		}
	};

	if (Array.isArray(data)) {
		isArrayVar = true;
		for (let i = 0; i < data.length; i++) {
			cleanedArray.push(decimalsFunction(data[i]));
		}
		return cleanedArray;
	}

	else {
		decimalsFunction(data);
		return y;
	}
};

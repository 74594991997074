import { Bar } from "react-chartjs-2";
import React from 'react';
import Data from "../utils/data";
import { colours } from "../common/color-pool.js";
import { Decimals } from "../common/decimals.js";
import { find  } from "../common/find";
import { transform } from "lodash";
import { render } from "react-testing-library";


class ChartResiendtelec extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			legendItems: [],
			chartInstance: [],
		};
		this.chart = [];
		this.legendClickCallback = this.legendClickCallback.bind(this);

	}

	legendClickCallback = (item, legendItemIndex) => {
		const chartClass = '.chart' + this.props.chartIndex;
		const chartContainer = document.querySelector(chartClass);
		if (item.currentTarget.getElementsByClassName('text-block')[0].style.textDecoration === 'line-through') {
			item.currentTarget.getElementsByClassName('text-block')[0].style.textDecoration = 'none';
		}
		else {
			item.currentTarget.getElementsByClassName('text-block')[0].style.textDecoration = 'line-through';
		}
		this.chart.map((chart, i) => {
			var dataItem = chart.chartInstance.config.data.datasets[legendItemIndex];
			if(dataItem.hidden == true){
				dataItem.hidden = false;
			} else {
				dataItem.hidden = true;
			}
			chart.chartInstance.update();
		})
	}


	componentDidMount() {
		const component = this.chart[0];
		component.chartInstance.generateLegend();
		this.setState({ legendItems: component.chartInstance.legend.legendItems });
	}

	render () {
		let data = this.props.data;
		let chartIndex = this.props.chartIndex;
		let chartIDs = [];
		let yAxis;
		let chartInstances = [];
		data = Data.cleanNumbers(data);

		const buildData = (data, state, supply_area, min, max, step) => {
			if (!step) {
				step = 25;
			}
			let rangeGroup = {}
			for (let i = min; i <= max; i += step) {
				rangeGroup[i] = [];
				data.forEach((d)=> {
					const value = Number(d[3]);
					if(i <= value && value < i+ step){
						rangeGroup[i].push(d);
					}
				})
			}

			let datasets = [];
			let labels = [];

			let d1 = [];
			let d2 = [];

			Object.keys(rangeGroup).forEach((key, index) => {
				labels.push(+key)
				const cost_group = rangeGroup[key];
				const moffer = cost_group.filter((cg)=> {
					return cg[0] === 'Market offer'
				})

				const soffer = cost_group.filter((cg)=> {
					return cg[0] === 'Standing offer'
				})

				d1.push(soffer.length);
				d2.push(moffer.length)
			})

			const ds1 = {
				label: 'Standing offer' ,
				data: d1,
				backgroundColor: colours.schemeTwo[1],
				borderWidth: -1,
				categoryPercentage: 1.0,
				barPercentage: 1.0,

			};

			const ds2 = {
				label: 'Market offer' ,
				data: d2,
				backgroundColor: colours.schemeTwo[0],
				borderWidth: -1,
				categoryPercentage: 1.0,
				barPercentage: 1.0,
			};	

			datasets = [ds1, ds2];

			return {
				datasets,
				labels
			}
		}


		const find = (key, data) => {
			let obj = data.find(elem => {
				return elem[0] == key;
			});

			if (obj) {
				return obj[1];
			}
			return false;
		}
		let xAxis = find('X axis', data);
		yAxis = find('Y axis', data);
		const rangeOption = data[7];
		const dataObj = data.slice(9);
		const state = dataObj[0][1];
		const supply_area = dataObj[0][2];
		let allData = {}
		let vw = this.props.vw;

		dataObj.forEach((item) => {
			const year = item[4];
			if(!allData[year]) {
				allData[year] = []
			}
			allData[year].push(item);
		})

		const dataByYear = Object.keys(allData).map((year, i) => {
			const ys = allData[year];
			const {labels, datasets} = buildData(ys, state, supply_area, Number(rangeOption[1]),  Number(rangeOption[2]), Number(rangeOption[4]));
			let title = year;

			return {
				datasets,
				labels,
				title
			}
		})

		const chartCreator = () => {
			let chartArray = [];
			dataByYear.forEach((year, index) => {
			const {labels, datasets, title} = year;
			let newID;
			let secondChart = false;
			if(index === 0) {
				newID = 'myChart -' + chartIndex;
			} else {
				secondChart = true;
				newID = 'myChart' + index + ' -' + chartIndex;
			}


			let step = Number(rangeOption[4]);
			if (!step) {
				step = 25;
			};
			const options = {
				aspectRatio: this.props.vw <= 750 ? 1 : 3,
				responsive: true,
					legend: {
						display: false,
					},
					title: {
						display: true,
						text: title,
						position: 'right',
					},
					legendCallback: function(chart) {
						let text = [];
						text.push('<div class=\'legend\' style="list-style-type: none; width: 100%;">');
						for (let i=0; i<chart.data.datasets.length; i++) {
							text.push('<div class="legend-block" style="display: inline-block">');
							text.push('<span style="background-color:' + chart.data.datasets[i].backgroundColor + ';margin-left: 10px;  margin-right: 10px; display: inline-block; width: 30px; height: 10px;"> </span>');
							text.push('<span class="text-block" style="font-family: \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif; font-size: 1.2rem; color: #666">' + chart.data.datasets[i].label + '</span>');
							text.push('</div>');
						}
						text.push('</div>');
						return text.join('');
					},
					scales: {
						xAxes: [
							{	
								id: 'axis1',
								stacked: true,
								display: false,
								ticks: {
									max: Number(rangeOption[2] - step),
								}
							},
							{
								id: 'axis2',
								scaleLabel: {
									labelString: xAxis,
									display: secondChart
								},
								max: Number(rangeOption[2]),
								gridLines: {
									display: secondChart,
									drawTicks: secondChart,
									drawOnChartArea: false
								},
								ticks: {
									autoSkip: true,
									display: secondChart,
									autoSkipPadding: 10,
									beginAtZero: false,
									minRotation: 90,
									maxTicksLimit: 15,
									userCallback: function (item, index, values) {
										if (Number(rangeOption[6])) {
											if((item - Number(rangeOption[1])) % Number(rangeOption[6]) === 0){
												return item;
											} else {
												return '';
											}
										}
										else {
											if (item % 100 === 0) {
												return item;
											}
											else {
												return '';
											}
										}
									},
								}
							}
						],
						yAxes: [
							{
								stacked: true,
								scaleLabel: {
									display: false,
									maxTicksLimit: 15,
									labelString: yAxis
								},
								ticks: {
									beginAtZero: true,
									autoSkip: true,
									max: Number(rangeOption[3]),
									userCallback: function (item, index, values) {
										if (Number(rangeOption[5])) {
											if((item) % Number(rangeOption[5]) === 0){
												return item;
											} else {
												return '';
											}
										}
										else {
											return item;
										}
									},
								}
							}
						]
					}
				}

				const chart = {
					labels,
					datasets
				};


				chartArray.push(
					<Bar height={null} width={null} key={index} id={newID} className="myChart" ref={ref => this.chart[index] = ref} data-key={index} data={chart} options={options}  style="height: 100%; width: 100%" />
				)
			});
			return chartArray
		};
		

		return (
			<div>
				<div className="chart-resiendtelec">
					<div className="left-block">{yAxis}</div>
						<div className={"right-block chart"}>
							{chartCreator()}
						</div>
					</div>
				<div className="chart-footer">
					<p className="chart-footer-title"></p>
					<div className="legend">
						{this.state.legendItems && this.state.legendItems.length &&
							(
								<div className='legend' >
									{this.state.legendItems.map((item, index) => {
										let colorIndex = index == 0 ? 1 : 0;
										const spanStyle = {
											backgroundColor: colours.schemeTwo[colorIndex],
										};
										return (
										<div key ={index}className="legend-block" onClick={(e) => this.legendClickCallback(e, index)}>
										<span
										style={spanStyle}
										className="legend-icon"></span>
										<span className="text-block">{item.text}</span>
										</div>
										);
									})}
								</div>
									)
								}
					</div>
				</div>
			</div>
		);
	}
}

export default ChartResiendtelec;
